import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { logoColor } from '../style/palette';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: #000;
  border: none;
  border-bottom: 1px solid ${logoColor};
  border-image: initial;
  height: 28px;
  line-height: 28px;

  ${(props) => props.theme};
  :before {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    margin-top: -1px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${logoColor};
  }
  :after {
    border-color: transparent transparent red transparent;
    top: 25%;
  }
  label {
    position: absolute;
    font-family: MyFont;
    top: 0px; /* 위치정렬 */
    left: 0px; /* 위치정렬 */
    width: 90%;
    height: 100%;
    padding: 0em 0.5em; /* select의 여백 크기 만큼 */
    /* line-height: 45px; */
    color: #000;
    /* IE8에서 label이 위치한 곳이 클릭되지 않는 것 해결 */
    cursor: pointer;
  }
`;
const SelectContainer = styled.select`
  width: 100%;
  height: auto; /* 높이 초기화 */
  line-height: normal; /* line-height 초기화 */
  font-family: MyFont; /* 폰트 상속 */
  opacity: 0; /* 숨기기 */
  filter: alpha(opacity=0); /* IE8 숨기기 */
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: 15px;
`;
const Option = styled.option`
  color: #000;
`;

export interface SelectBoxDataType {
  label: string;
  value: any;
}

interface SelectBoxProps {
  data: SelectBoxDataType[];
  name?: string;
  initialValue?: string;
  onChange?: (value: any) => void;
  style?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  placeholder?: string;
  value?: string;
}

const Select: React.FC<SelectBoxProps> = ({
  data,
  name,
  initialValue,
  onChange = () => {},
  style,
  selectStyle,
  placeholder,
  value: propValue,
}) => {
  const [value, setValue] = useState('');

  // props 에 initial value 가 있으면 그것을 선택해줌.
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let dataIndex = 0;

    const { value: parentValue, children } = e.target;
    for (let i = 0; i < children.length; i++) {
      const childValue = children[i].getAttribute('value');
      if (parentValue === childValue) {
        dataIndex = parseInt(
          children[i].getAttribute('data-key') as string,
          10,
        );
        break;
      }
    }

    if (propValue === undefined) {
      setValue(e.target.value);
    } else {
      setValue(propValue);
    }

    onChange(data[dataIndex]);
  };

  return (
    <Wrapper style={style}>
      <label style={{ color: value ? '#000' : '#808080' }}>
        {value ? value : placeholder}
      </label>
      <SelectContainer
        value={value}
        onChange={handleChange}
        style={selectStyle}
        name={name}
      >
        <option hidden value=""></option>
        {data.map((item, i) => (
          <Option key={i} value={item.label} data-key={i}>
            {item.label}
          </Option>
        ))}
      </SelectContainer>
    </Wrapper>
  );
};

export default Select;
