import { ToastOptions, Slide } from 'react-toastify';
import './toastStyle.css';

const palette = {
  gray: [
    '#f8f9fa',
    '#f1f3f5',
    '#e9ecef',
    '#dee2e6',
    '#ced4da',
    '#adb5bd',
    '#868e96',
    '#495057',
    '#343a40',
    '#212529',
  ],
  cyan: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    '#0c8599',
    '#0b7285',
  ],
};

export const logoColor = '#7361d4';

export const hilightedLogoColor = '#651fff';

export const profileFormWidth = '700px';

export const footerHeight = '3rem';

export const PAGE_TITLE_TOP_POSITION = '76px';

export const PAGE_TITLE_LEFT_POSITION = '80px';

export const PAGE_MENU_LEFT_POSITION = '25px';

export const SIDEBAR_WIDTH = '234px';

export const SIDEBAR_LEFT_PADDING = '29px';

export const MAIN_CONTENT_WIDTH = '65%';

export const CONTENT_BORDER_RADIUS = '10px';

export const toastStyle: ToastOptions = {
  autoClose: 1500,
  position: 'top-center',
  transition: Slide,
};

export const lightPurple = '#f7f5ff';

export const skinFontColor = '#242424';

export const customSkinColor = '#fecb00';

export const lightCustomSkinColor = '#fffae4';

export const filterBorderColor = '#dedede';

export const PRONUNCIATION_LEFT_POSITION = 'calc(10% + 22px)';

export const BANNER_HEIGHT = `105px`;

export const CONTAINER_HEIGHT = '100vh';

export const CONTAINER_HEIGHT_WITH_BANNER = `calc(${CONTAINER_HEIGHT} - ${BANNER_HEIGHT})`;

export default palette;
